import { uniq, uniqBy } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Avatar from "react-avatar";
import { DebounceInput } from "react-debounce-input";
import useApiManager from "../../hooks/useApiManager";
import useDebounceState from "../../hooks/useDebounceState";
import { modyficateKeys, months, parseDate } from "../../utils";
import SearchInp from "../core/SearchInput";
import StatelessTabs from "../core/Tabs/StatelessTabs";
import {
  ChatHeaderMoreIcon,
  ChatHeaderSearchIcon,
  ChatSearchBottomArrowIcon,
  ChatSearchTopArrowIcon
} from "../Public/markup/icons";
import { errorToast } from "../toasts";
import ChatInfo from './ChatInfo';
import ChatItem from "./ChatItem";
import Message from "./ChatMessage";
import { ChatBtn, ChatFooter, CustomSelect, generateHtmlMessage, itemsByTab, mapStateByMethod, parseZulipMessage, tabs } from './Components';
import SaveImage from './floppy.png';
import Messages from "./Messages";
import './index.scss';
import { useSelector } from "react-redux";
import SimplePullClient from "./pullService";
import ErrorPlug from "../ErrorBoundry/ErrorPlug";

const Chat = () => {
  const userState = useSelector(state => state.user)

  const {
    registerQueue,
    getProfile,
    getUsersList,
    getChannels,
    getMessages,
    getChannelInfo,
    unregisterQueue,
    updatePresence,
    sendMessage,
    readMessage,
    uploadFile,
    dialogWriting,
    deleteMessage,
    updateMessage,

    state,
    setValue,
    reset,
    setValues,
    dispatch,
    api,
    stateRef,
    addFile,
    getFiles,
    searchMessage
  } = useApiManager([
    { action: "registerQueue", method: "GET" },
    { action: "getProfile" },
    { action: "getUsersList" },
    { action: "getChannels" },
    { action: "getMessages" },
    { action: "unregisterQueue" },
    { action: "updatePresence" },
    { action: "getFiles" },
    { action: "sendMessage" },
    { action: "readMessage" },
    { action: "uploadFile" },
    { action: "dialogWriting" },
    { action: "deleteMessage" },
    { action: "updateMessage" },
    { action: "addFile" },
    { action: "getChannelInfo" },
    { action: "searchMessage" },
    
  ], {
    defaultPayload: { c: 'panpartner:chat.ajax' },
    initialState: {
      isChatOpen: false,
      activeTab: 0,
      currentChat: null,// объект со всей информацией о чате
      showChatInfo: false,
      loadingState: [],
      messagesDump: {},
      lastEventId: null,
      presences: {},
      filterMessages: "all",
      showAddList: false,
      selectedChats: [],
      forwardedMessage: null,
      reply: null,
      allMessages: []
    }
  })

  const {
    activeTab,
    isChatOpen,
    currentChat,
    showChatInfo,
    loadingState,
    user,
    members,
    streams,
    messagesDump,
    queueId,
    lastEventId,
    searchValue,
    chatSearchValue,
    searchResult,
    chatSearchResult,
    presences,
    scrollMessage,
    filterMessages,
    showAddList,
    selectedChats,
    forwardedMessage,
    reply,
    allMessages
  } = state;

  const userId = useMemo(() => userState.pullConfig?.publicChannels ? +Object.keys(userState.pullConfig?.publicChannels).flat(userState.pullConfig?.publicChannels)?.[0] : false,
    [userState.pullConfig])

  const chatUser = useMemo(() => state.members && userId ? state.members.find(({ id }) => id === userId) : false,
    [userId, state.members])

  const pullClientRef = useRef(null)

  useEffect(() => {
    if (pullClientRef.current) return;

    const pullClient = new SimplePullClient({
      config: userState.pullConfig,
      userId: userId,
      siteId: 'default'
    });
    pullClientRef.current = pullClient;
    pullClient.start();

    const unsubscribe = pullClient.subscribe(parsedMessage => {
      const { text } = parsedMessage;
      console.log('СООБЩЕНИЕ :>> ', parsedMessage);
      if (text?.module_id === "im" && text?.command === "messageChat") {
        const newMessage = {
          id: text.params.message.id,
          chat_id: text.params.message.chatId,
          author_id: text.params.message.senderId,
          date: new Date(text.params.message.date).toISOString(),
          text: text.params.message.text,
          ...text.params.message
        };

        setValues(prevState => {
          const updatedAllMessages = [...prevState.allMessages, newMessage];
          const updatedMembers = prevState.members
            .map(member =>
              member.chat_id === newMessage.chatId
                ? { ...member, message: newMessage }
                : member
            )


          return {
            ...prevState,
            allMessages: updatedAllMessages,
            members: updatedMembers,
            currentChat: prevState.currentChat?.chat_id === newMessage.chatId
              ? { ...prevState.currentChat, message: newMessage }
              : prevState.currentChat,
          };
        });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [userId]);

  const observerRef = useRef(null);

  const inputRef = useRef(null)

  const scrollToDown = () => document.querySelector(".open-chat__overfield")?.scrollTo?.(0, 99999);

  const getLastMessage = (chat, messages = allMessages) => {
    return [...messages]
      .reverse()
      .find(message => {
        if (chat.stream_id) {
          return message.stream_id === chat.stream_id;
        }
        return (
          message.chat_id === chat.chat_id &&
          (!message.isSystem || message.params?.COMPONENT_ID !== "OwnChatCreationMessage")
        );
      });
  }

  const changeMessage = (messageId, newValues = {}, deleteMessage = false) => {
    setValues(prevState => {
      const messageIndex = prevState.allMessages.findIndex(message => message.id === messageId);
      const currentMessage = [...prevState.allMessages][messageIndex];
      if (!currentMessage) return prevState;

      let messages = [...prevState.allMessages];
      messages[messageIndex] = { ...currentMessage, ...newValues };
      if (deleteMessage) messages = messages.filter(message => message.id !== messageId);

      return {
        allMessages: messages,
        currentChat: {
          ...prevState.currentChat,
          message: [...messages].reverse().find(message =>
            message.type === "private" && message.display_recipient.some(rec => rec.id === prevState.currentChat.id)
          )
        }
      };
    });
  }

  console.log('state, chatUser :>> ', state, chatUser);

  const deleteMsg = id => {
    changeMessage(id, { process: "delete" });
    deleteMessage({ "params[message_id]": id }).then(({ resp }) => {
      const deleteResult = resp.deleteResult;
      if (deleteResult?.result === "error") {
        changeMessage(id, { error: deleteResult?.msg });
        return;
      }
      changeMessage(id, {}, true);
    });
  }

  const forwardMessage = () => {
    if (!selectedChats?.length) return setValues(prevState => ({ ...prevState, selectedChats: [], forwardedMessage: null }));
    const isSaved = currentChat.email === user.email
    const content = `@_**${isSaved ? user.full_name : currentChat.name ?? currentChat.full_name}|${currentChat.id}** [писал/а](https://zulip.bars-mc.ru/#narrow/dm/${currentChat.id}-dm/near/${forwardedMessage.id}):
      \`\`\`quote
      ${forwardedMessage.content}
      \`\`\``

    // sendMessage({
    //   ...modyficateKeys({
    //     type: activeTab === 0 ? "private" : "stream",
    //     content: content.trim(),
    //     read_by_sender: true
    //   }, "]", "params["),
    //   ...selectedChats.reduce((acc, current, index) => {
    //     acc[`params[to][${index}]`] = current.user_id ?? current.email
    //     acc[`params[reply_to][${index}]`] = current.email
    //     return acc
    //   }, {}),
    //   // [activeTab === 1 ? "params[to][0]" : "params[to]"]: activeTab === 1 ? currentChat.name : currentChat.email,
    //   requestID: `sendMessage-${Date.now()}`,
    // })

    //   .then(({ resp }) => {

    //     setValues(prevState => ({
    //       ...prevState,
    //       reply: null,
    //       selectedChats: [],
    //       forwardedMessage: null,
    //       members: prevState.members.map(member => { // channels отдельно
    //         if (!selectedChats.some(({ chat_id }) => member.id === chat_id)) return member
    //         const tmp = { ...member }
    //         tmp.messages = [...tmp.messages, {
    //           id: resp.sendResult?.id,
    //           content: `<p>
    //       <span class=\"user-mention silent\" data-user-id=\"11\">${isSaved ? user.full_name : currentChat.name ?? currentChat.full_name}|${currentChat.id}</span> 
    //       <a href=\"/#narrow/dm/${currentChat.id}-dm/near/${reply.id}\">писал/а</a>:<br>\n<code>${forwardedMessage.content}\n</code></p>`,
    //           content_type: "text/html",
    //           timestamp: `${Date.now()}`.slice(0, -3),
    //           sender_id: user.user_id,
    //           flags: ["read"]
    //         }]
    //         return tmp
    //       })
    //     }));

    //     scrollToDown()

    //   })
  }

  const focus = () => inputRef.current?.focus?.()

  useEffect(() => {
    focus()
  }, [currentChat, reply])

  const replyMessage = () => {

    if (!reply) return
    const content = `<div class="reply" message-id="${reply.id}">${currentChat.isSavedMessage ? "Вы писали:" : `${reply?.sender_full_name ?? currentChat.full_name} писал/а:`} 
    <br/>
    <div class="reply-content"> ${reply.content} </div>
    </div>
      ${messagesDump?.[currentChat?.requestID] ?? ""}
    `

    send(content, {
      ...modyficateKeys({
        type: activeTab === 0 ? "private" : "stream",
        content: content.trim(),
        read_by_sender: true
      }, "]", "params["),
      ...[currentChat].reduce((acc, current, index) => {
        acc[`params[to][${index}]`] = current.user_id ?? current.email
        acc[`params[reply_to][${index}]`] = current.email
        return acc
      }, {}),
      [activeTab === 1 ? "params[to][0]" : "params[to]"]: activeTab === 1 ? currentChat.name : currentChat.email,
      [activeTab === 1 ? "params[reply_to][0]" : "params[reply_to]"]: activeTab === 1 ? currentChat.name : currentChat.email,
      requestID: `sendMessage-${Date.now()}`,
    })
  }

  const readMessages = messages => {
    if (Array.isArray(messages) && !messages?.length || !currentChat?.id) return;
    messages.forEach(messageId => {
      readMessage({
        dialogId: currentChat.chat_id || currentChat.id,
        messageId,
      }).then(() => {
        setValues(prevState => ({
          currentChat: {
            ...prevState.currentChat,
            messages: prevState.currentChat.messages.map(message =>
              message.id === messageId
                ? { ...message, viewed: true, unread: false }
                : message
            ),
          },
          allMessages: prevState.allMessages.map(message =>
            message.id === messageId
              ? { ...message, viewed: true, unread: false }
              : message
          ),
        }));
      });
    });
  };

  const [readedMessages, setReadedMessages, setDebouncedValues] = useDebounceState({
    setState: (prev, ...args) => (uniq([...prev, +args[0].id.replace("message-", "")])),
    fn: (message, prev) => readMessages(uniq([...prev, +message.id.replace("message-", "")])),
    defaultState: [],
  })

  const scrollToMessage = ({ currentChat, id }) => {
    if (!id) return
    if (currentChat) setValue("currentChat", currentChat)
    const chatElem = document.querySelector(".open-chat__overfield");
    const messageElem = document.getElementById(id);
    chatElem.scrollTo({
      top: messageElem?.offsetTop,
    });
  }

  const send = (messageContent, payload) => {
    const content = typeof messageContent === "string" ? messageContent : messagesDump[currentChat?.requestID];

    if (!content || !currentChat) return;

    const tmpMessage = {
      status: "sending",
      id: Date.now(),
      chat_id: currentChat.chat_id,
      author_id: userId,
      date: new Date().toISOString(),
      text: content,
      isSystem: false,
      replaces: [],
      uuid: null,
      forward: null,
      params: [],
      viewedByOthers: false,
      unread: false,
      "viewed": true,
      "files": payload?.attach || [],
      "author": chatUser
    }
    setValues(prevState => ({
      ...prevState,
      allMessages: [...prevState.allMessages, tmpMessage],
      currentChat: { ...prevState.currentChat, message: tmpMessage },
      messagesDump: { ...prevState.messagesDump, [currentChat?.requestID]: "" },
      reply: null,
      selectedChats: [],
      forwardedMessage: null,
    }));

    setTimeout(() => scrollToDown());

    return sendMessage({
      dialogId: currentChat.id || currentChat.chat_id,
      message: content,
      attach: payload?.attach || [],
    })
      .then(({ resp }) => {

        if (resp?.result?.id) {
          setValues(prevState => ({
            ...prevState,
            allMessages: prevState.allMessages.map((msg) =>
              msg.id === tmpMessage.id ? { ...msg, id: resp.result.id, status: "sent" } : msg
            ),
          }));
        }
      });

  };

  const searchMessages = (searchValue, type) => {
    setValues(prevState => ({
      ...(type === "chat" ? { chatSearchValue: searchValue } : { searchValue }),
      ...(searchValue
        ? {}
        : {
          isSearchOpen: false,
          chatSearchResult: false,
          searchResult: false,
        }),
    }));
    if (!searchValue) return;

    const updateSearchResults = (messages) => messages.map(message => ({
      ...message,
      match_content: message.text.replace(
        new RegExp(`(${searchValue})`, "gi"),
        "<mark>$1</mark>"
      ),
    }));

    if (type === "chat") {
      searchMessage({
        dialogId: currentChat.id || currentChat.chat_id,
        "filter[searchMessage]": searchValue,
      }).then(({ resp }) => {
        if (!resp?.result?.messages) return;
        setValues(prevState => ({
          ...prevState,
          chatSearchResult: updateSearchResults(resp.result.messages),
        }));
      });
      return;
    }

    const members = state.members || [];
    const searchResults = members.filter(({ title = "", description = "" }) =>
      [title, description].some(field =>
        field.toLowerCase().includes(searchValue.toLowerCase())
      )
    ).map(member => ({
      ...member,
      match_content: [member.title, member.description]
        .filter(Boolean)
        .map(field =>
          field.replace(
            new RegExp(`(${searchValue})`, "gi"),
            "<mark>$1</mark>"
          )
        )
        .join(" "),
    }));

    setValues(prevState => ({
      ...prevState,
      searchResult: searchResults,
    }));
  };

  useEffect(() => {
    getChannels()
      .then((resp) => {
        setValues(prevState => mapStateByMethod.getChannels(resp, {}, prevState))
      })

    // Promise.all([
    //   registerQueue(),
    //   getProfile(),
    //   getUsersList(),
    // ]).then(arr => {
    //   setValues(prevState => arr.reduce((acc, curr) => ({ ...acc, ...mapStateByMethod[curr.config[1]](curr.resp) }), prevState))
    // })


  }, [])

  useEffect(() => {
    if (!isChatOpen) {
      const timer = setTimeout(() => setIsAnimating(false), 700)
      return () => clearTimeout(timer)
    }

    setIsAnimating(true);
    // presence()
    // return () => updatePresence({ "params[status]": "idle" })
  }, [isChatOpen])


  const chatRequestId = currentChat?.requestID

  useEffect(() => {
    if (!chatRequestId || loadingState.includes(`getMessages-${chatRequestId}`)) return;

    getMessages({ dialogId: currentChat.id || currentChat.chat_id }, `getMessages-${chatRequestId}`).then(resp => {
      setValues(prevState =>
        mapStateByMethod.getMessages(resp, { requestID: chatRequestId }, stateRef.current)
      );
      scrollToMessage({ id: scrollMessage });
    });

    const callback = entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) setReadedMessages(entry.target);
      });
    };

    observerRef.current = new IntersectionObserver(callback, {
      root: document.querySelector(".open-chat__overfield"),
      rootMargin: "0px",
      threshold: 1.0,
    });
  }, [chatRequestId]);

  const chatList = useMemo(() => {
    const items = state.members || [];

    if (searchValue && searchResult) {
      return searchResult.map(message => {
        // ...existing code...
      });
    }

    return items
      .map(item => ({
        ...item,
        messages: item.message ?? [],
        requestID: `${itemsByTab[activeTab]}-${item.id}`,
        message: getLastMessage(item, allMessages) || item.message,
      }))
      .filter(item => {
        if (item.type === "chat") return activeTab === 1;
        return activeTab === 0;
      }).sort((a, b) => new Date(b.message?.date || 0) - new Date(a.message?.date || 0));;
  }, [state, activeTab]);

  useEffect(() => {//del и убрать кнопку из App. Для неавторизованных оставить, новая вводная.
    window.addEventListener('load', () => window.BX?.SiteButton.hide());
  }, [])

  const [isAnimating, setIsAnimating] = useState(false);

  const messagesList = useMemo(() => {
    if (!stateRef.current.currentChat) return [];
    return stateRef.current.allMessages
      .filter(({ chat_id }) => stateRef.current.currentChat.chat_id === chat_id)
      .map((message) => ({
        ...message,
        id: message.id,
        authorId: message.author_id,
        date: message.date,
        text: message.text,
        unread: message.unread,
        viewed: message.viewed,
        viewedByOthers: message.viewedByOthers,
        params: message.params,
        replaces: message.replaces,
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [stateRef.current.allMessages, stateRef.current.currentChat]);

  const showChatWindow = isChatOpen || isAnimating;

  return (
    <>
      {showChatWindow && <div className={`overchat ${isChatOpen ? 'active' : 'hide'}`}>
        <div className={`chat ${isChatOpen ? 'active' : 'hide'}`}>
          <div className="chat__close-chat" onClick={() => {
            setValues(prevState => ({
              ...prevState,
              isChatOpen: false,
              currentChat: null,
              showChatInfo: false,
              isSearchOpen: false,
              chatSearchResult: false,
              searchResult: false,
            }))
          }
          }>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6 6L11 1" stroke="white" stroke-linecap="round" /><path d="M1 11L6 6L11 11" stroke="white" stroke-linecap="round" /></svg>
          </div>
          <div className="chat__left">
            <div className="chat__search">
              <SearchInp
                value={searchValue ?? ""}
                isSearching={loadingState.includes("search-list")}
                placeholder="Поиск"
                searchIcon
                onChange={e => searchMessages(e.target.value, "list")}
                reset={() => searchMessages("", "list")}

                renderInput={props => <DebounceInput
                  minLength={1}
                  debounceTimeout={500}
                  autoComplete="off"
                  className="section-search__inp"
                  {...props}
                />}
              />
            </div>

            <div className="chat__tabs">
              <StatelessTabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={v => setValue("activeTab", v)}
                isInline
              />
            </div>

            <div className="chat__sort">
              <div className="chat__sort-select">
                <CustomSelect
                  options={[
                    { id: "all", label: 'Все' },
                    { id: "read", label: 'Непрочитанные' },
                    { id: "active", label: 'Активные' },
                  ]}
                  onChange={({ id }) => setValue("filterMessages", id)}
                />
              </div>
              {!!forwardedMessage && <div className={'chat__sort-edit chat__sort-edit_sort'} onClick={() => setValues(prevState => ({ ...prevState, selectedChats: [], forwardedMessage: null }))}>
                Отмена
              </div>}
              {!!forwardedMessage && <div className={'chat__sort-edit chat__sort-edit_sort'} onClick={forwardMessage}>Готово</div>}
            </div>
            {loadingState.includes("search-list") && <div className="chat__empty-text">Поиск...</div>}
            {!!searchValue  && !chatList?.length && <div className="chat__empty-text">Ничего не найдено</div>}
            {!!searchResult?.length && <ul className="chats-list">
              {searchResult.map(item => (
                <ChatItem
                  userName={item.title}
                  message={item.match_content}
                  onClick={() => setValue("currentChat", item)}
                  key={item.id}
                />
              ))}
            </ul>}
            {!searchResult?.length && <ul className="chats-list">
              {chatList && chatList.map((item) => {
            
                const shotIsTypingStatus = item.isTyping && !item.requestID.includes("search-") && !item.isSavedMessage
                return <ChatItem
                  userName={item.title}
                  // isFile="Секрктный документ.pdf"
                  lastMessageTime={shotIsTypingStatus ? false : !!item.message?.date && parseDate(item.message.date, false, true).formattedTime}
                  sortered={!!forwardedMessage}
                  selected={selectedChats.some(selected => item.email === selected.email)}
                  selectItem={() => setValues(prevState => ({
                    ...prevState,
                    selectedChats: selectedChats.some(selected => item.email === selected.email) ? selectedChats.filter(v => v.email !== item.email) : [...selectedChats, item]
                  }))}
                  logo={item.avatar?.url}
                  isActive={(!item.message?.scroll && currentChat?.requestID === item.requestID) || scrollMessage === `message-${item.id}`}
                  message={shotIsTypingStatus ? `${currentChat.title} пишет...` : item.message?.text}
                  isImage={item.message?.files ? item.message.files.filter(file => file.type === "image").at(-1) : []}
                  images={item.message?.files ? item.message.files.filter(file => file.type === "image") : []}
                  onClick={() => {
                    if (currentChat?.id === item.id) return
                    if (item.requestID.includes("search-") && currentChat?.id === item.id) {
                      setValues(prevState => ({ ...prevState, scrollMessage: item.message?.scroll ? `message-${item.id}` : null, }))
                      return scrollToMessage({ id: `message-${item.id}` })
                    }
                    if (forwardedMessage) return
                    setValues(prevState => ({ ...prevState, currentChat: item, chatSearchResult: false, scrollMessage:  item.message?.scroll ? `message-${item.id}` : null, reply: null }))
                  }}
                  key={item.requestID}
                  id={item.id}
                  isOnline={item?.email !== user?.email && presences?.[item.email]?.aggregated?.status === "active"}
                  unreadMessages={!!item.messages?.length && item.messages.reduce((acc, current) => current.unread ? acc + 1 : acc, 0)}
                />
              }
              )}

            </ul>}

          </div>
          <div className="chat__right" style={{ filter: !forwardedMessage ? "none" : `grayscale(90%) blur(1px)` }}>
            {!!currentChat?.user && <div className="open-chat">
              <div className="open-chat__header">
                <div className="open-chat__header-left">
                  <div className="open-chat__user">
                    <div className="open-chat__user-logo">
                      {currentChat.user?.avatar ?
                        <img src={currentChat.user.avatar} style={{ width: 48, height: 48, borderRadius: "50%" }} />

                        :
                        <Avatar
                          name={currentChat.title ?? currentChat.name}
                          size="48"
                          round={true}
                          textSizeRatio={2.857}
                        />
                      }
                      <div className="open-chat__user-status open-chat__user-status_active" />
                    </div>
                    <div className="open-chat__user-info">
                      <p className="open-chat__user-name">{currentChat.title ?? currentChat.name}</p>
                      {currentChat.email && <p className="open-chat__user-post">{currentChat.email}</p>}
                      {currentChat.description && <p className="open-chat__user-post">{currentChat.description}</p>}
                      {/* <p className="open-chat__user-post">Ваш личный менеджер</p> */}
                    </div>
                  </div>
                </div>
                <div className="open-chat__header-right">
                  <span className={`open-chat__search-btn ${currentChat?.isSearchOpen ? 'open-chat__search-btn_active' : ''}`}
                    onClick={() => {
                      setValues(prevState => ({
                        ...prevState,
                        currentChat: { ...prevState.currentChat, isSearchOpen: !prevState.currentChat.isSearchOpen }
                      }))
                      if (chatSearchValue) searchMessages(chatSearchValue, "chat")
                    }}>
                    <ChatHeaderSearchIcon />
                  </span>
                  <span className="open-chat__header-actionbtn" onClick={() => {
                    setValues(prevState => ({
                      ...prevState,
                      showChatInfo: true,
                      currentChat: { ...prevState.currentChat, isSearchOpen: false }
                    }))
                  }}>
                    <ChatHeaderMoreIcon />
                  </span>
                </div>
              </div>

              {currentChat?.isSearchOpen &&
                <div className="open-chat__search">
                  <div className="open-chat__search-area">
                    <div className="open-chat__search-arrows">
                      <span><ChatSearchBottomArrowIcon /></span>
                      <span><ChatSearchTopArrowIcon /></span>
                    </div>
                    <div className="open-chat__search-inp">
                      <SearchInp
                        value={chatSearchValue ?? ""}
                        isSearching={loadingState.includes("search-chat")}
                        placeholder="Поиск"
                        searchIcon
                        onChange={e => searchMessages(e.target.value, "chat")}
                        reset={() => searchMessages("", "chat")}

                        renderInput={props => <DebounceInput
                          minLength={1}
                          debounceTimeout={500}
                          autoComplete="off"
                          className="section-search__inp"
                          {...props}
                        />}
                      />
                    </div>
                    {false && searchValue !== '' &&
                      <div className="open-chat__search-count">1/5</div>
                    }

                  </div>

                  {!!chatSearchResult?.length && <div className="open-chat__search-list">
                    <ul className="chats-list chats-list_search">
                      {chatSearchResult.map(item => (
                        <ChatItem
                          userName={item.sender_full_name ?? item.name}
                          lastMessageTime={!!item.timestamp && parseDate(new Date(+`${item.timestamp}000`), false, true).formattedTime}
                          message={item.match_content}
                          onClick={() => scrollToMessage({ id: `message-${item.id}` })}
                          key={item.id}

                        />
                      ))}
                    </ul>
                  </div>}
                </div>
              }
              {!currentChat && <div className="chat__empty-text">Пожалуйста, выберите чат</div>}
              {currentChat && !messagesList?.length && !loadingState.includes(currentChat.requestID) && !loadingState.includes(`getMessages-${currentChat.requestID}`) && <div className="chat__empty-text">Сообщений пока нет</div>}
              {currentChat && !messagesList?.length && loadingState.includes(`getMessages-${currentChat.requestID}`) && <div className="chat__empty-text">Загрузка...</div>}
              <ErrorPlug stopSending={true}>
                {!!messagesList?.length && <Messages currentChat={stateRef.current.currentChat} setValues={setValues} key={currentChat?.user_id}>
                  {messagesList.map((message, i) => {
                    const {
                      days,
                      month,
                      year,
                      formattedTime,
                    } = parseDate(new Date(message.date), false, true)

                    const { days: previousMessageDay, month: previousMessageMoth, year: previousMessageYear } = parseDate(messagesList[i === 0 ? i : i - 1].date, false, true)
                    const dateText = ((previousMessageDay != days && month !== previousMessageMoth && year !== previousMessageYear) || i === 0) && `${days} ${months[month].toLowerCase()} ${year}`


                    return <React.Fragment key={message.id}>
                      {dateText && <div className="open-chat__date">{dateText}</div>}
                      {message.author_id === 0 && <div className="open-chat__date">{message.text}</div>}
                      {message.author_id !== 0 && <Message
                        // image={y}
                        time={formattedTime}
                        fromMe={userId === message.authorId}
                        {...message} // Ensure text is included in props
                        content={message.text} // Explicitly pass text as content
                        observer={observerRef.current}
                        deleteMsg={() => deleteMsg(message.id)}
                        forwardMessage={() => setValue("forwardedMessage", message)}
                        replyMessage={() => setValue("reply", message)}
                        scrollToMessage={scrollToMessage}
                        message={message}
                      />}
                    </React.Fragment>
                  })}

                </Messages>}
              </ErrorPlug>
              {!!currentChat && <ChatFooter
                state={state}
                stateRef={stateRef}
                setValue={setValue}
                setValues={setValues}
                send={reply ? replyMessage : send}
                uploadFile={uploadFile}
                setTyping={dialogWriting}
                disabled={!!forwardedMessage}
                reply={reply}
                inputRef={inputRef}
              />}
            </div>}

            {showChatInfo && <ChatInfo
              close={() => setValue("showChatInfo", false)}
              {...currentChat}
              getChannelInfo={getChannelInfo}
              getFiles={getFiles}
            />}


          </div>
        </div>
      </div>}
      {chatList?.length && <ChatBtn onClick={() => setValue("isChatOpen", !isChatOpen)} />}
    </>
  )
}

export default Chat