import { useRef } from 'react';
import { newApi } from '../api';
import useUniversalReducer from './useUniversalReducer';

const useApiManager = (preRequests = [], config = {}) => {

  const { initialState = [], defaultPayload = {} } = config;

  const { c, method = "POST" } = defaultPayload;

  const { state, stateRef, setValue, reset, setValues, dispatch } = useUniversalReducer({
    loadingState: [],
    ...initialState
  });


  const toggleLoading = ({ action, requestID }, isLoading) => {
    setValue("loadingState", prev => isLoading ? [...prev, requestID ?? action] : prev.filter(v => v !== requestID && v !== action))
  }

  const api = new Proxy(newApi, {
    get(target, prop) {
      if (typeof target[prop] === 'function') {
        return new Proxy(target[prop], {
          apply: (target, thisArg, argumentsList) => {
            const [action, c, params, reqId] = argumentsList;
            console.log('argumentsList :>> ', argumentsList);
            const requestID = reqId ?? `${c}-${action}`;

            const loadingParams = { action, requestID }
            toggleLoading(loadingParams, true)
            return Reflect.apply(target, thisArg, argumentsList)
              .then(r => {
                toggleLoading(loadingParams, false)
                return { ...r, ...loadingParams }
              }, err => {
                toggleLoading(loadingParams, false)
                return err
              });
          }
        });
      } else {
        return Reflect.get(target, prop);
      }
    }
  });

  return {
    state,
    setValue,
    reset,
    setValues,
    dispatch,
    toggleLoading,

    api,
    stateRef,
    ...Object.fromEntries(preRequests.map(({ action, ...config }) => ([action, (payload, requestId) => api[config?.method ?? method](config.c ?? c, action, payload ?? config.payload, requestId)]))),
  }
}

export default useApiManager;