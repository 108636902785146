/**
 * Класс для управления соединением с сервером Bitrix Push & Pull через WebSocket или Long Polling.
 * Предоставляет подписку на события и отправку сообщений через REST API.
 * Использует автоматическое переподключение и переключение между типами соединений при сбоях.
 *
 * @class
 * @example
 * const config = {
 *   server: {
 *     websocket_secure: 'wss://rtc-cloud.bitrix.info/subws/',
 *     long_pooling_secure: 'https://rtc-cloud.bitrix.info/subws/',
 *     publish_secure: 'https://rtc-cloud.bitrix.info/rest/'
 *   },
 *   channels: {
 *     private: { id: 'private-channel-id' },
 *     shared: { id: 'shared-channel-id' }
 *   },
 *   clientId: 'your-client-id'
 * };
 * const pullClient = new SimplePullClient({ config, siteId: 'default' });
 * pullClient.start();
 * const unsubscribe = pullClient.subscribe(message => console.log(message));
 */

class SimplePullClient {
    #socket = null;
    #xhr = null;
    #subscribers = new Map();
    #reconnectTimeout = null;
    #attempt = 0;
    #isConnected = false;
    #connectionType = 'websocket'; // 'websocket' или 'longpolling'

    constructor({ config = {}, siteId }) {
        this.config = config;
        this.siteId = siteId;
    }

    // Формирование пути подключения
    #getConnectionPath(type) {
        const { server, channels, clientId } = this.config;
        if (!channels) return null;
        const params = {
            CHANNEL_ID: ["private", "shared"].map(ch => channels[ch].id).join('/'),
            clientId,
            revision: 19
        };

        const paths = {
            websocket: server.websocket_secure,
            longpolling: server.long_pooling_secure
        };
        //return "wss://devcrm.panpartner.ru/bitrix/subws/?CHANNEL_ID=c5db58d81caee2cb7a65de2b757f2a7e%3A375a57502dee89adfed52e36a669aada.ed780f2c51b10ccffce1079515512cff852690ec%2F20c77e0d6f90b66328677f2dc0fbf87c.6e67d09709e55f128e995da9996b13b17f2e6649&binaryMode=false&revision=19"
        console.log('ПУТЬ ПОДКЛЮЧЕНИЯ', `${paths[type]}?${new URLSearchParams(params).toString()}`);
        return `${paths[type]}?${new URLSearchParams(params).toString()}`;
    }

    start() {
        if (this.#isConnected) return this;
        this.#connect();
        return this;
    }

    #connect() {
        this.#disconnect();

        if (this.#connectionType === 'websocket') {
            this.#socket = new WebSocket(this.#getConnectionPath('websocket'));

            this.#socket.onopen = () => this.#handleOpen('websocket');
            this.#socket.onmessage = event => this.#handleMessage(event.data);
            this.#socket.onclose = event => this.#handleClose('websocket', event);
            this.#socket.onerror = error => this.#handleError('websocket', error);
        } else {
            this.#xhr = new XMLHttpRequest();
            this.#xhr.open('GET', this.#getConnectionPath('longpolling'));
            this.#xhr.onreadystatechange = () => this.#handleXhrStateChange();
            this.#xhr.timeout = 60000; // Таймаут 60 секунд, как в оригинале
            this.#xhr.ontimeout = () => this.#handleTimeout();
            this.#xhr.send();
        }
    }

    #disconnect() {
        this.#socket?.close();
        this.#socket = null;
        this.#xhr?.abort();
        this.#xhr = null;
    }

    #handleOpen(type) {
        this.#isConnected = true;
        this.#attempt = 0;
        clearTimeout(this.#reconnectTimeout);
        console.log(`${type === 'websocket' ? 'WebSocket' : 'Long Polling'} подключен`);
    }

    #handleMessage(data) {
        const rawMessages = data.split(/#!NGINXNME!#/).filter(Boolean);

        rawMessages.forEach(rawMessage => {
            const cleanedMessage = rawMessage.replace(/#!NGINXNMS!#/, '').trim();

            try {
                const parsedMessage = JSON.parse(cleanedMessage);
                this.#broadcast(parsedMessage);
            } catch (error) {
                console.error('Не удалось разобрать :>>', error, rawMessage);
            }
        });

        if (this.#connectionType === 'longpolling') this.#connect();
    }

    #handleClose(type, event) {
        this.#isConnected = false;
        console.log(`${type === 'websocket' ? 'WebSocket' : 'Long Polling'} закрыт: код ${event.code}, причина: ${event.reason}`);
        this.#scheduleReconnect();
    }

    #handleError(type, error) {
        this.#isConnected = false;
        console.error(`Ошибка ${type === 'websocket' ? 'WebSocket' : 'Long Polling'}:`, error);
        this.#scheduleReconnect();
    }

    #handleXhrStateChange() {
        if (this.#xhr.readyState !== 4) return;
        if (this.#xhr.status === 200) {
            this.#handleOpen('longpolling');
            this.#handleMessage(this.#xhr.responseText);
        } else {
            this.#handleError('longpolling', new Error(`Статус ${this.#xhr.status}`));
        }
    }

    #handleTimeout() {
        this.#xhr.abort();
        this.#connect(); // Повторяем запрос при таймауте
    }

    #parseMessages(data) {
        try {
            const parsed = JSON.parse(data);
            return Array.isArray(parsed) ? parsed : [parsed];
        } catch (error) {
            console.error('Ошибка парсинга:', error, data);
            return [];
        }
    }

    // Рассылка сообщений подписчикам
    #broadcast(message) {
        for (const [, callback] of this.#subscribers) {
            callback(message);
        }
    }

    // Подписка на события
    subscribe(callback) {
        const id = Symbol();
        this.#subscribers.set(id, callback);
        return () => this.#subscribers.delete(id);
    }

    // Отправка сообщения через REST
    async sendMessage({ users, moduleId, command, params, expiry = 3600 }) {
        const response = await fetch(this.config.server.publish_secure, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                method: 'im.message.add',
                params: {
                    USER_IDS: users,
                    MODULE_ID: moduleId,
                    COMMAND: command,
                    PARAMS: params,
                    EXPIRY: expiry
                }
            })
        });

        const result = await response.json();
        if (result.error) throw new Error(result.error.message);
        return result;
    }

    // Переподключение
    #scheduleReconnect() {
        clearTimeout(this.#reconnectTimeout);
        const delay = this.#getReconnectDelay();
        console.log(`Переподключение через ${delay} сек, попытка #${this.#attempt}`);

        this.#reconnectTimeout = setTimeout(() => {
            this.#updateConnectionType();
            this.#connect();
        }, delay * 1000);
    }

    #getReconnectDelay() {
        const delays = [
            [0, 0.5],  // Первая попытка: 0.5 сек
            [3, 15],   // До 3 попыток: 15 сек
            [5, 45],   // До 5 попыток: 45 сек
            [10, 600], // До 10 попыток: 10 мин
        ];

        const [maxAttempts, delay] = delays.find(([max]) => this.#attempt < max) || [Infinity, 3600];
        this.#attempt++;
        return delay + delay * Math.random() * 0.2;
    }

    #updateConnectionType() {
        this.#connectionType = this.#attempt > 3 && this.config.server.long_pooling_secure
            ? 'longpolling'
            : 'websocket';
    }

    stop() {
        this.#disconnect();
        clearTimeout(this.#reconnectTimeout);
        this.#isConnected = false;
    }

    get isConnected() {
        return this.#isConnected;
    }

    get connectionType() {
        return this.#connectionType;
    }
}

export default SimplePullClient