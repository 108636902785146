export const getUniversalReducer = initialState => (state, { type, payload }) => {
  const { key, value } = payload
  switch (type) {
    case 'set':
      return { ...state, [key]: typeof value === "function" ? value(state[key]) : value };
    case 'apply':
      return { ...state, ...typeof payload === "function" ? payload(state) : payload };
    case 'reset':
      return initialState;
    default:
      throw new Error();
  }
};