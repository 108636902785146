import React from "react";

const MiniLoader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 50 50"
    className="mini-loader"
  >
    <circle
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke="#ccc"
      strokeWidth="4"
      strokeDasharray="31.415, 31.415"
      transform="rotate(-90 25 25)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 25 25"
        to="360 25 25"
        dur="1s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

export default MiniLoader;
