import React, { memo, useState, useEffect } from "react";
import Avatar from "react-avatar";
import bigStub from '../../assets/images/chat/BIGstub.jpg';
import {
    ChatAddUserIcon,
    ChatBackArrowIcon,
    ChatDownloadIcon,
    ChatFooterFileIcon,
    ChatFooterImageIcon,
    ChatUsersIcon,
    MenuCloseIcon
} from "../Public/markup/icons";
import { Accordion } from './Components';
import './index.scss';
import stubhouse from './stubhouse.png';
import UserInChatItem from "./UserInChatItem";
import { PhotoPopup } from "../Viewer/PhotoSlider";
import files from "../../reducers/files";
import { set } from "lodash";

const UserList = ({ users, onBack }) => (
    <div className="chat-users">
        <div className="chat-users__back" onClick={onBack}>
            <ChatBackArrowIcon />
        </div>
        <ul className="chat-users__list">
            {users.map(user => (
                <UserInChatItem
                    key={user.id}
                    name={user.name}
                    post={user.workPosition}
                    phone={user.phones?.mobile || "N/A"}
                    logo={user.avatar}
                />
            ))}
        </ul>
    </div>
);

const UploadedImages = ({ files, onImageClick }) => (
    <div className="chat-info__uploaded-images">
        {files.map(file => (
            <img
                key={file.id}
                className="chat-info__uploaded-image"
                onClick={() => onImageClick(file.urlShow)}
                src={file.urlPreview || stubhouse}
                alt={file.name}
            />
        ))}
    </div>
);

const UploadedFiles = ({ files }) => (
    <div className="chat-info__uploaded-files">
        {files.map(file => (
            <div key={file.id} className="chat-info__uploaded-file">
                <div className="chat-info__uploaded-filetitle">{file.name}</div>
                <div className="chat-info__uploaded-filedate">
                    <span>{new Date(file.date).toLocaleDateString()}</span>
                    <span className="chat-info__uploaded-icon">
                        <a href={file.urlDownload} download>
                            <ChatDownloadIcon />
                        </a>
                    </span>
                </div>
            </div>
        ))}
    </div>
);

const defState = {
    showUsers: false,
    channelInfo: null,
    openPhoto: false,
    photoItems: [],
    isLoading: true,
}
const ChatInfo = memo(({ close, avatar, title, email, name, description, getChannelInfo, id: chatId, getFiles }) => {
    const [state, setState] = useState(defState);

    useEffect(() => {
        if (chatId) {
            getChannelInfo({ dialogId: chatId }).then(resp => {
                setState(prev => ({
                    ...prev,
                    channelInfo: resp.resp.result,
                    photoItems: resp.resp.result?.files?.filter(file => file.type === "image").map(file => ({ src: file.urlShow })) || [],
                    isLoading: false
                }));
            }, err => {
                setState(prev => ({ ...prev, isLoading: false }));
            });
        }
        return () => setState(defState);
    }, [chatId]);
    console.log('state :>> ', state);
    if (state.showUsers) {
        return <UserList users={state.channelInfo?.users || []} onBack={() => setState(prev => ({ ...prev, showUsers: false }))} />;
    }

    return (
        <div className="chat-info">
            <div className="chat-info__close" onClick={close}><MenuCloseIcon /></div>
            <div className="chat-info__user">
                <div className="chat-info__user-logo">
                    {!avatar?.url ? (
                        <Avatar
                            name={title ?? name}
                            size="110"
                            round={true}
                            textSizeRatio={2.857}
                        />
                    ) : (
                        <img className="chat-info__user-image" src={avatar?.url || avatar} alt="" />
                    )}
                </div>
                <div className="chat-info__user-name">{title ?? name}</div>
                {description && <div className="chat-info__user-post">{description}</div>}
                <a href={`mailto:${email}`} className="chat-info__user-email">{email}</a>
            </div>
            
            {state.isLoading && <div className="chat-info__loading">Загрузка...</div>}

            {!state.isLoading && <>
                <div className="chat-info__users-list">
                    <ul>
                        <li>
                            <span className="chat-info__users-icon" onClick={() => setState(prev => ({ ...prev, showUsers: true }))}>
                                <ChatUsersIcon />{state.channelInfo?.users?.length || 0} участников
                            </span>
                            <span className="chat-info__adduser-icon">
                                <ChatAddUserIcon />
                            </span>
                        </li>
                    </ul>
                </div>

                <div className="chat-info__action-list">
                    <Accordion key={state.channelInfo?.files?.length} title="Фото и видео" Icon={ChatFooterImageIcon} number={state.channelInfo?.files?.filter(file => file.type === "image" || file.type === "video").length || 0}>
                        <UploadedImages files={state.channelInfo?.files?.filter(file => file.type === "image") || []} onImageClick={() => setState(prev => ({ ...prev, openPhoto: true }))} />
                    </Accordion>
                    <Accordion title="Файлы" key={state.channelInfo?.files?.length} Icon={ChatFooterFileIcon} number={state.channelInfo?.files?.filter(file => file.type === "file").length || 0}>
                        <UploadedFiles files={state.channelInfo?.files?.filter(file => file.type === "file") || []} />
                    </Accordion>
                </div>

                <div className="chat-info__leave-links">
                    <div className="chat-info__leave-link">Удалить группу</div>
                    <div className="chat-info__leave-link">Выйти из чата</div>
                </div>
            </>
            }
            <PhotoPopup
                open={state.openPhoto}
                items={state.photoItems}
                index={0}
                onClose={() => setState(prev => ({ ...prev, openPhoto: false }))}
            />
        </div>
    );
});

export default ChatInfo;
